<template>
    <div id="numberOfPanels">
        <CSBreadcrumb/>
        <div class="result-panel">
            <CSTable>
                <template v-slot:thead>
                    <tr>
                        <th>面板编号</th>
                        <th>面板类型</th>
                        <th>所在楼栋</th>
                        <th>所在楼层</th>
                        <th>进/出</th>
                        <th>屏幕分辨率</th>
                        <th>备注</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr
                            v-for="panel in panelList"
                            :key="panel.id"
                    >
                        <td>{{panel.deviceNo}}</td>
                        <td>{{processingPanelType(panel.type)}}</td>
                        <td>{{panel.buildingName}}</td>
                        <td>{{panel.floor}}</td>
                        <td>{{judgeInAndOut(panel.type)}}</td>
                        <td>{{processingPanelResolution(panel.resolution)}}</td>
                        <td>
              <span
                      class="allow-click"
                      @click="lookRemarks(panel.comment)"
                      v-if="panel.comment"
              >
                查看
              </span>
                            <span v-else>-</span>
                        </td>
                    </tr>
                </template>
            </CSTable>
            <Pagination/>
        </div>
    </div>
</template>

<script>
    import CSBreadcrumb from "@/components/common/CSBreadcrumb";
    import Pagination from "@/components/Pagination.vue";
    import {
        queryGroupDevicesPage,
    } from "@/requestUrl"
    import CSTable from "@/components/common/CSTable";

    export default {
        name: "numberOfPanels",
        props: {
            groupId: Number
        },
        components: {
            CSTable,
            CSBreadcrumb,
            Pagination
        },
        data() {
            return {
                // 面板数量列表
                panelList: [],
                // 面板类型
                PanelType: [
                    {
                        id: 1,
                        name: "闸机(进)"
                    },
                    {
                        id: 2,
                        name: "闸机(出)"
                    },
                    {
                        id: 3,
                        name: "会议室"
                    },
                    {
                        id: 4,
                        name: "墙面广告面板"
                    },
                    {
                        id: 5,
                        name: "员工考勤面板"
                    },
                    {
                        id: 6,
                        name: "楼层门禁"
                    }
                ],
                // 面板分辨率
                resolvingPowerList: [
                    {
                        id: 1,
                        name: "800 * 1280"
                    },
                    {
                        id: 2,
                        name: "1920 * 1080"
                    }
                ],
            }
        },
        created() {
            this.getNumberOfpanels();
            this.$vc.on(this.$route.path, "pagination_page", "event", this.getNumberOfpanels);

        },
        methods: {
            // 获取面板数量
            async getNumberOfpanels(pageNo = 1,pageSize = 10) {
                let res = await this.$fly.get(queryGroupDevicesPage, {
                    groupId: this.groupId,
                    pageSize,
                    pageNo
                })
                if (res.code != 0) {
                    return
                }
                if (pageNo === 1) {
                    this.$vc.emit(this.$route.path, "pagination", "init", {
                        total: res.data.total,
                        pageSize,
                        currentPage: pageNo,
                    });
                }
                this.panelList = res.data.datas;
            },
            // 查看备注
            lookRemarks(comment) {
                this.$CSDialog.alert({
                    title: "查看备注",
                    message: comment,
                })
            },
            // 处理面板类型
            processingPanelType(type) {
                let name = "";
                this.PanelType.forEach(item => {
                    if (item.id == type) {
                        name = item.name;
                    }
                })
                return name;
            },
            // 判断进出的方法
            judgeInAndOut(type) {
                if ([4, 5, ''].includes(type)) {
                    return '-';
                } else if ([1, 3, 6].includes(type)) {
                    return '进';
                } else if ([2].includes(type)) {
                    return '出';
                }
            },
            // 处理面板分辨率
            processingPanelResolution(id) {
                let name = "";
                this.resolvingPowerList.forEach(item => {
                    if (item.id === id) {
                        name = item.name;
                    }
                })
                return name;
            },
        },
    }
</script>

<style lang="stylus" scoped>

</style>
